import React from 'react';

const GetIconByPath = (path, className) => {
  switch (path) {
    case 'email':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="0" y="0">
          <path className="background-icon" fill="#1cabe2" d="M29.333 8c0-1.467-1.2-2.667-2.667-2.667h-21.333c-1.467 0-2.667 1.2-2.667 2.667v16c0 1.467 1.2 2.667 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667v-16zM26.667 8l-10.667 6.667-10.667-6.667h21.333zM26.667 24h-21.333v-13.333l10.667 6.667 10.667-6.667v13.333z" />
        </svg>
      );
    case 'close':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="112" y="0">
          <path className="background-icon" fill="#1cabe2" d="M25.333 8.547l-1.88-1.88-7.453 7.453-7.453-7.453-1.88 1.88 7.453 7.453-7.453 7.453 1.88 1.88 7.453-7.453 7.453 7.453 1.88-1.88-7.453-7.453z" />
        </svg>
      );
    case 'social-ico-linkedin':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="168" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <path fill="#2d2926" d="M8.403 13.13h3.119v10.87h-3.119v-10.87zM9.883 11.77h-0.022c-1.129 0-1.861-0.83-1.861-1.882 0-1.074 0.754-1.888 1.905-1.888s1.858 0.812 1.88 1.885c0 1.052-0.73 1.886-1.902 1.886zM24 24h-3.536v-5.626c0-1.472-0.554-2.476-1.77-2.476-0.93 0-1.448 0.678-1.688 1.332-0.091 0.234-0.077 0.56-0.077 0.888v5.882h-3.504s0.046-9.964 0-10.87h3.504v1.706c0.207-0.746 1.326-1.81 3.112-1.81 2.217 0 3.958 1.565 3.958 4.93v6.044z" />
        </svg>
      );
    case 'social-ico-youtube':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="224" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <path fill="#2d2926" d="M24 18.851v-5.702s0-2.749-2.758-2.749h-10.486s-2.756 0-2.756 2.749v5.702s0 2.749 2.756 2.749h10.486s2.758 0 2.758-2.749zM19.106 16.008l-5.238 3.066v-6.134l5.238 3.067z" />
        </svg>
      );
    case 'burger-menu':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="280" y="0">
          <path fill="#1cabe2" d="M4 24h24v-2.667h-24v2.667zM4 17.333h16.889v-2.667h-16.889v2.667zM4 8v2.667h24v-2.667h-24z" />
        </svg>
      );
    case 'arrow-small-up':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="336" y="0">
          <path className="background-icon" fill="#1cabe2" d="M7.607 19.060l1.88 1.88 6.12-6.107 6.12 6.107 1.88-1.88-8-8z" />
        </svg>
      );
    case 'arrow-small-down':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="392" y="0">
          <path className="background-icon" fill="#1cabe2" d="M23.607 12.94l-1.88-1.88-6.12 6.107-6.12-6.107-1.88 1.88 8 8z" />
        </svg>
      );
    case 'arrow-small-right':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="448" y="0">
          <path fill="#1cabe2" d="M18.667 24l1.88-1.88-6.107-6.12 6.107-6.12-1.88-1.88-8 8z" />
        </svg>
      );
    case 'insert-drive-file':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="504" y="0">
          <path className="background-icon" fill="#1cabe2" d="M18.667 2.667h-10.667c-1.467 0-2.653 1.2-2.653 2.667l-0.013 21.333c0 1.467 1.187 2.667 2.653 2.667h16.013c1.467 0 2.667-1.2 2.667-2.667v-16l-8-8zM8 26.667v-21.333h9.333v6.667h6.667v14.667h-16z" />
        </svg>
      );
    case 'access-time':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="560" y="0">
          <path className="background-icon" fill="#1cabe2" d="M15.987 2.667c-7.36 0-13.32 5.973-13.32 13.333s5.96 13.333 13.32 13.333c7.373 0 13.347-5.973 13.347-13.333s-5.973-13.333-13.347-13.333zM16 26.667c-5.893 0-10.667-4.773-10.667-10.667s4.773-10.667 10.667-10.667 10.667 4.773 10.667 10.667-4.773 10.667-10.667 10.667zM16.667 9.333h-2v8l7 4.2 1-1.64-6-3.56v-7z" />
        </svg>
      );
    case 'arrow-right':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="616" y="0">
          <path fill="#1cabe2" d="M5.333 17.333h16.227l-7.453 7.453 1.893 1.88 10.667-10.667-10.667-10.667-1.88 1.88 7.44 7.453h-16.227z" />
        </svg>
      );
    case 'arrow-left':
      return (
        <svg className={`icon ${className}`} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon/arrow-right-nav-copy">
              <g id="Group" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(0.000000, -0.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                <polygon id="Path" fill="#FFFFFF" transform="translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) " points="20 11 7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13" />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'shopping-cart':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="672" y="0">
          <path className="background-icon" fill="#1cabe2" d="M20.733 17.333c1 0 1.88-0.547 2.333-1.373l4.773-8.653c0.493-0.88-0.147-1.973-1.16-1.973h-19.733l-1.253-2.667h-4.36v2.667h2.667l4.8 10.12-1.8 3.253c-0.973 1.787 0.307 3.96 2.333 3.96h16v-2.667h-16l1.467-2.667h9.933zM8.213 8h16.2l-3.68 6.667h-9.36l-3.16-6.667zM9.333 24c-1.467 0-2.653 1.2-2.653 2.667s1.187 2.667 2.653 2.667 2.667-1.2 2.667-2.667-1.2-2.667-2.667-2.667zM22.667 24c-1.467 0-2.653 1.2-2.653 2.667s1.187 2.667 2.653 2.667 2.667-1.2 2.667-2.667-1.2-2.667-2.667-2.667z" />
        </svg>
      );
    case 'dropdown-down':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="728" y="0">
          <path fill="#1cabe2" d="M9.333 13.333l6.667 6.667 6.667-6.667z" />
        </svg>
      );
    case 'favorite':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="784" y="0">
          <path className="background-icon" fill="#1cabe2" d="M22 4c-2.32 0-4.547 1.080-6 2.787-1.453-1.707-3.68-2.787-6-2.787-4.107 0-7.333 3.227-7.333 7.333 0 5.040 4.533 9.147 11.4 15.387l1.933 1.747 1.933-1.76c6.867-6.227 11.4-10.333 11.4-15.373 0-4.107-3.227-7.333-7.333-7.333zM16.133 24.733l-0.133 0.133-0.133-0.133c-6.347-5.747-10.533-9.547-10.533-13.4 0-2.667 2-4.667 4.667-4.667 2.053 0 4.053 1.32 4.76 3.147h2.493c0.693-1.827 2.693-3.147 4.747-3.147 2.667 0 4.667 2 4.667 4.667 0 3.853-4.187 7.653-10.533 13.4z" />
        </svg>
      );
    case 'attach-file':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="840" y="0">
          <path className="background-icon" fill="#1cabe2" d="M22 8v15.333c0 2.947-2.387 5.333-5.333 5.333s-5.333-2.387-5.333-5.333v-16.667c0-1.84 1.493-3.333 3.333-3.333s3.333 1.493 3.333 3.333v14c0 0.733-0.6 1.333-1.333 1.333s-1.333-0.6-1.333-1.333v-12.667h-2v12.667c0 1.84 1.493 3.333 3.333 3.333s3.333-1.493 3.333-3.333v-14c0-2.947-2.387-5.333-5.333-5.333s-5.333 2.387-5.333 5.333v16.667c0 4.053 3.28 7.333 7.333 7.333s7.333-3.28 7.333-7.333v-15.333h-2z" />
        </svg>
      );
    case 'tune':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="896" y="0">
          <path fill="#1cabe2" d="M4 22.667v2.667h8v-2.667h-8zM4 6.667v2.667h13.333v-2.667h-13.333zM17.333 28v-2.667h10.667v-2.667h-10.667v-2.667h-2.667v8h2.667zM9.333 12v2.667h-5.333v2.667h5.333v2.667h2.667v-8h-2.667zM28 17.333v-2.667h-13.333v2.667h13.333zM20 12h2.667v-2.667h5.333v-2.667h-5.333v-2.667h-2.667v8z" />
        </svg>
      );
    case 'arrow-small-left':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="952" y="0">
          <path fill="#1cabe2" d="M13.333 8l-1.88 1.88 6.107 6.12-6.107 6.12 1.88 1.88 8-8z" />
        </svg>
      );
    case 'social-ico-email':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="1064" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <path fill="#2d2926" d="M24 11.2c0-0.88-0.72-1.6-1.6-1.6h-12.8c-0.88 0-1.6 0.72-1.6 1.6v9.6c0 0.88 0.72 1.6 1.6 1.6h12.8c0.88 0 1.6-0.72 1.6-1.6v-9.6zM22.4 11.2l-6.4 4-6.4-4h12.8zM22.4 20.8h-12.8v-8l6.4 4 6.4-4v8z" />
        </svg>
      );
    case 'social-ico-facebook':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="1120" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <path fill="#2d2926" d="M17.052 25.6h-3.902v-9.201h-1.95v-3.17h1.95v-1.904c0-2.586 1.101-4.125 4.229-4.125h2.604v3.171h-1.627c-1.218 0-1.298 0.443-1.298 1.27l-0.006 1.587h2.949l-0.345 3.17h-2.604v9.201z" />
        </svg>
      );
    case 'social-ico-twitter':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="1176" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <g transform="translate(8, 8)">
            <svg x="0" y="0" width="16" height="16" viewBox="0 0 1200 1227" fill="none">
              <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#2d2926" />
            </svg>
          </g>
        </svg>
      );
    case 'social-ico-instagram':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="1232" y="0">
          <path className="background-icon" fill="#fff" d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
          <path fill="#2d2926" d="M20.908 8h-9.816c-1.704 0-3.092 1.386-3.092 3.092v9.816c0 1.706 1.387 3.092 3.092 3.092h9.816c1.704 0 3.092-1.386 3.092-3.092v-9.816c0-1.706-1.387-3.092-3.092-3.092zM21.795 9.845l0.354-0.002v2.712l-2.704 0.008-0.010-2.712 2.36-0.006zM13.717 14.357c0.512-0.709 1.344-1.174 2.283-1.174s1.771 0.465 2.283 1.174c0.334 0.462 0.534 1.029 0.534 1.642 0 1.552-1.264 2.816-2.817 2.816s-2.816-1.264-2.816-2.816c0-0.614 0.2-1.18 0.533-1.642zM22.442 20.908c0 0.846-0.688 1.534-1.534 1.534h-9.816c-0.846 0-1.534-0.688-1.534-1.534v-6.55h2.39c-0.206 0.508-0.322 1.062-0.322 1.642 0 2.412 1.962 4.376 4.374 4.376s4.376-1.964 4.376-4.376c0-0.581-0.118-1.134-0.323-1.642h2.389v6.55z" />
        </svg>
      );
    case 'search':
      return (
        <svg className={`icon ${className}`} width="32" height="32" viewBox="0 0 32 32" x="1288" y="0">
          <path className="background-icon" fill="#1cabe2" d="M20.667 18.667h-1.053l-0.373-0.36c1.307-1.52 2.093-3.493 2.093-5.64 0-4.787-3.88-8.667-8.667-8.667s-8.667 3.88-8.667 8.667 3.88 8.667 8.667 8.667c2.147 0 4.12-0.787 5.64-2.093l0.36 0.373v1.053l6.667 6.653 1.987-1.987-6.653-6.667zM12.667 18.667c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6z" />
        </svg>
      );
    case 'arrow-big-left':
      return (
        <svg className={`icon icon--big-arrow ${className}`} width="42" height="42" viewBox="0 0 42 42">
          <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <circle cx="20" cy="20" r="20" stroke="#1CABE2" />
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(8 8)" />
              <path className="internal-arrow-icon" fill="#2D2926" d="M20 11L7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13z" transform="translate(8 8)" />
            </g>
          </g>
        </svg>
      );
    case 'arrow-big-right':
      return (
        <svg className={`icon icon--big-arrow ${className}`} width="32" height="32" viewBox="0 0 42 42">
          <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <circle cx="20" cy="20" r="20" stroke="#1CABE2" />
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="rotate(-180 16 16)" />
              <path className="internal-arrow-icon" fill="#2D2926" d="M20 11L7.83 11 13.42 5.41 12 4 4 12 12 20 13.41 18.59 7.83 13 20 13z" transform="rotate(-180 16 16)" />
            </g>
          </g>
        </svg>
      );
    case 'shop':
      return (
        <svg className={`icon ${className}`} width="18" height="18" viewBox="0 0 12 16" version="1.1">
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Components/1366/Nav/Menu" transform="translate(-1095.000000, -8.000000)">
              <g id="Group-12-Copy" transform="translate(944.000000, 7.000000)">
                <g id="shopping_bag-black-24dp-2" transform="translate(148.000000, 0.000000)">
                  <g>
                    <rect id="Rectangle" x="0" y="0" width="18" height="18" />
                    <path fill="#fff" d="M13.5,4.5 L12,4.5 C12,2.8425 10.6575,1.5 9,1.5 C7.3425,1.5 6,2.8425 6,4.5 L4.5,4.5 C3.675,4.5 3,5.175 3,6 L3,15 C3,15.825 3.675,16.5 4.5,16.5 L13.5,16.5 C14.325,16.5 15,15.825 15,15 L15,6 C15,5.175 14.325,4.5 13.5,4.5 Z M9,3 C9.825,3 10.5,3.675 10.5,4.5 L7.5,4.5 C7.5,3.675 8.175,3 9,3 Z M13.5,15 L4.5,15 L4.5,6 L6,6 L6,7.5 C6,7.9125 6.3375,8.25 6.75,8.25 C7.1625,8.25 7.5,7.9125 7.5,7.5 L7.5,6 L10.5,6 L10.5,7.5 C10.5,7.9125 10.8375,8.25 11.25,8.25 C11.6625,8.25 12,7.9125 12,7.5 L12,6 L13.5,6 L13.5,15 Z" id="Shape" fillRule="nonzero" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case 'location':
      return (
        <svg className={`icon ${className}`} width="18" height="18" viewBox="0 0 18 18">
          <g fill="none" fillRule="evenodd">
            <g>
              <g transform="translate(-688 -7) translate(688 7)">
                <path d="M0 0L18 0 18 18 0 18z" />
                <path fill="#fff" fillRule="nonzero" d="M9 9c-.825 0-1.5-.675-1.5-1.5S8.175 6 9 6s1.5.675 1.5 1.5S9.825 9 9 9zm4.5-1.35C13.5 4.927 11.512 3 9 3 6.487 3 4.5 4.928 4.5 7.65c0 1.755 1.463 4.08 4.5 6.855 3.037-2.775 4.5-5.1 4.5-6.855zM9 1.5c3.15 0 6 2.415 6 6.15 0 2.49-2.002 5.438-6 8.85-3.997-3.412-6-6.36-6-8.85C3 3.915 5.85 1.5 9 1.5z" />
              </g>
            </g>
          </g>
        </svg>
      );
    case 'arrow-up-white':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
          <path d="M9.5 18L9.5 2M9.5 2L1.5 8.55738M9.5 2L17.5 8.55738" stroke="white" strokeWidth="2" />
        </svg>
      );
    default:
  }
};

const Icon = ({ path, className }) => GetIconByPath(path, className);

export default Icon;
