import React, { useEffect, useState } from 'react';
import Link from '../TransitionLink';
import logoDesktop from '../../assets/images/logo-brand.svg';
import logoMobile from '../../assets/images/logo-mobile.svg';
import { ButtonLink } from '../Link';
import Icon from '../Icons';
import useRemoveBackspaces from '../../hooks/hooks.strings';
import useMatchMedia from '../../hooks/match-media';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const PrimaryNav = ({
  labelButton, linkButton, openSecondaryNav, handleOpenMobileMenu, navigation, activeSecondaryNav
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const mediaToMatch = '(max-width: 767px)';
  /** Using external HOOKS to listen window resize */
  useMatchMedia(setIsMobile, mediaToMatch);

  const handleGaEvent = (label) => {
    useGaEvent(
      'Navigation',
      'TOP_L1',
      `${label}`
    );
  };

  const handleGaEventDonation = (url) => {
    if (url.includes('donazioni.unicef.it')) {
      useGaEvent('Donations', 'Donate Now');
    }
  };

  const waitDelay = () => {
    if (isOpenMenu) {
      return 'primary-nav__container--open';
    }
    return '';
  };

  const { labelButtonValue } = {
    labelButtonValue: labelButton.value
  };

  const handleClick = (index, label) => {
    openSecondaryNav(index);
    handleGaEvent(label);
  };

  useEffect(() => {
    if (activeSecondaryNav >= 0) {
      setTimeout(() => {
        setIsOpenMenu(true);
      }, 300);
    } else {
      setTimeout(() => {
        setIsOpenMenu(false);
      }, 300);
    }
  }, [activeSecondaryNav]);

  return (
    <div className="primary-nav">
      <div className={`primary-nav__container ${waitDelay()}`}>
        <div className="primary-nav__content">
          <button onClick={() => handleOpenMobileMenu()} type="button" className="primary-nav__burger-btn">
            <Icon className="icon--36" path="burger-menu" />
          </button>
          <Link className="primary-nav__logo" to="/">
            <figure className="primary-nav__figure">
              {isMobile ? <img src={logoMobile} alt="Logo di Unicef" className="primary-nav__image" /> : <img src={logoDesktop} alt="Logo di Unicef" className="primary-nav__image" />}
            </figure>
          </Link>
          <div className="primary-nav__menu-container">
            <nav className="primary-nav__menu">
              <ul className="primary-nav__menu-list">
                {
                  navigation && navigation.map((item, index) => {
                    const { label, id } = item;
                    const primaryNavMenuBtnActive = activeSecondaryNav === index
                      ? 'primary-nav__menu-btn--active' : '';
                    const menuBtnClasses = useRemoveBackspaces(`
                      primary-nav__menu-btn
                      label-small
                      ${primaryNavMenuBtnActive}
                    `);
                    return (
                      <li className="primary-nav__menu-item" key={id}>
                        <button
                          type="button"
                          className={menuBtnClasses}
                          onClick={() => { handleClick(index, label); }}
                        >
                          {label}
                        </button>
                      </li>
                    );
                  })
                }
              </ul>
            </nav>
            <ButtonLink
              kind="primary"
              color="yellow"
              url={linkButton}
              label={labelButtonValue}
              className="primary-nav__btn"
              onClick={() => handleGaEventDonation(linkButton)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryNav;
