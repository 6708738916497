import React, { useState } from 'react';
import Img from 'gatsby-image';
import Link from '../TransitionLink';
import Icon from '../Icons';
import useRemoveBackspaces from '../../hooks/hooks.strings';
import { useCreateSlug, useTraverseTree } from '../../hooks/archive-hooks';
import Linked from '../Linked';
import Logo from '../Logo';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const SecondaryNav = ({
  navigation, closeSecondaryNav, activeSecondaryNav, isOpen
}) => {
  const [focused, setFocused] = useState(-1);

  const handleGaEvent = (firstLevel, secondLevel) => {
    useGaEvent(
      'Navigation',
      'TOP_L2',
      `${firstLevel}::${secondLevel}`
    );
  };

  const menuLabel = {
    7479245: 'In Italia',
    7550939: 'Nel Mondo'
  };
  return (
    <div className={`secondary-nav__menu-container 
      ${isOpen ? 'secondary-nav__menu--open' : ''}`}
    >
      {
        navigation && navigation.map((menuItem, index) => {
          const {
            label, description, menuList, titleFeatured, urlFeatured, coverImageFeatured, id
          } = menuItem;
          return (
            <div
              className={`secondary-nav__menu-wrapper 
              ${activeSecondaryNav !== index ? '' : 'secondary-nav__menu-wrapper--active'}`}
              key={id}
            >
              <div className="secondary-nav__btn-wrapper">
                <button
                  type="button"
                  className="secondary-nav__btn"
                  onClick={() => closeSecondaryNav()}
                >
                  <Icon className="icon--24 secondary-nav__icon" path="close" />
                </button>
              </div>
              <div className="secondary-nav__first-column">
                <h4 className="secondary-nav__title heading-md">{label}</h4>
                <p className="secondary-nav__paragraph paragraph-small">{description}</p>
              </div>
              <nav className="secondary-nav__second-column">
                <ul className="secondary-nav__menu-list">
                  {
                    menuList && menuList.map((subMenuItem) => {
                      const menuTemp = [];
                      const slugDef = useTraverseTree(menuTemp, subMenuItem);
                      const slug = useCreateSlug(slugDef);
                      const { title } = subMenuItem;
                      const menuItemClasses = useRemoveBackspaces(`
                        secondary-nav__menu-item
                        ${(focused !== subMenuItem.id && focused >= 0)
                        ? 'secondary-nav__menu-item--not-focused' : ''}
                        label-small`);
                      return (
                        <li className={menuItemClasses} key={subMenuItem.id}>
                          {menuLabel[subMenuItem.originalId] && (
                            <div className="secondary-nav__label-menu">{menuLabel[subMenuItem.originalId]}</div>
                          )}
                          <Link
                            onMouseOver={() => setFocused(subMenuItem.id)}
                            onMouseLeave={(() => setFocused(-1))}
                            onClick={() => handleGaEvent(label, title)}
                            to={`/${slug}/`}
                            className="secondary-nav__link"
                          >
                            {title}
                          </Link>
                        </li>
                      );
                    })
                  }
                </ul>
              </nav>
              <Linked
                url={urlFeatured}
                className="secondary-nav__third-column"
              >
                <Img
                  fluid={{
                    ...coverImageFeatured.fluid,
                    aspectRatio: 16 / 9
                  }}
                  alt={coverImageFeatured.alt}
                  className={`secondary-nav__image 
                  ${activeSecondaryNav !== index ? '' : 'secondary-nav__image--visible'}`}
                />
                <p className="secondary-nav__paragraph heading-xs">{titleFeatured}</p>
              </Linked>
            </div>
          );
        })
      }
      <Logo className="logo--black secondary-nav__logo" />
    </div>
  );
};

export default SecondaryNav;
