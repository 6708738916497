import React from 'react';

const Container = ({
  children,
  className,
  backgroundColor,
  marginTop,
  marginBottom,
  refProps
}) => {
  const dictionary = {
    piccolo: 'small',
    grande: 'big',
    zero: 'zero'
  };

  const top = dictionary[marginTop];
  const bottom = dictionary[marginBottom];

  const classes = `${className || ''} 
    ${backgroundColor ? `bg-color-${backgroundColor}` : ''}
    ${(marginTop !== undefined && marginTop !== 'default') ? `margin-top-${top}` : ''}
    ${(marginBottom !== undefined && marginBottom !== 'default') ? `margin-bottom-${bottom}` : ''}`.replace(/\s+/g, ' ');

  return (
    <section ref={refProps} className={classes}>
      {children}
    </section>
  );
};

export default Container;
