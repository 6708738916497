import React from 'react';
import Link from '../TransitionLink';
import { createRouteLink, isInternal } from '../../hooks/archive-hooks';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const Linked = ({
  children, className, url, onClick
}) => {
  if (!isInternal(url)) {
    const handleGaEvent = () => {
      useGaEvent('Outbound Link', url);
      onClick();
    };
    return (
      <a onClick={() => handleGaEvent()} href={url} className={className} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link onClick={onClick} to={`${createRouteLink(url)}/`} className={className}>
      {children}
    </Link>
  );
};

Linked.defaultProps = {
  onClick: () => {}
};

export default Linked;
