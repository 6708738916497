/* eslint-disable */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import logo from '../../assets/images/logo-brand-white.png';

const TransitionLink = ({
  children,
  onMouseOver,
  onMouseLeave,
  to,
  onClick,
  className
}) => {
  return (
    <AniLink
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      to={to}
      bg="#1cabe2"
      cover
      direction="up"
      className={className}
      onClick={onClick}
    >
      {children}
    </AniLink>
  );
};

export default TransitionLink;
