import React, {
  useState, useLayoutEffect, useRef, useEffect
} from 'react';
import { gsap } from 'gsap';
import { globalHistory } from '@reach/router';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import Navigation from '../Navigation';
import MobileNavigation from '../MobileNavigation';

gsap.registerPlugin(CSSRulePlugin);

const path = globalHistory.location.pathname;

const Header = ({
  navigation, topNavigation, labelButton, linkButton, setIsMaskVisible
}) => {
  const [isMenuMobileActive, setIsMenuMobileActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeSecondaryNav, setActiveSecondaryNav] = useState(-1);

  const headerRef = useRef(null);
  const navbarRef = useRef(null);

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      const isMatchedMedia = window.matchMedia('(max-width: 1366px)').matches;
      if (isMatchedMedia) {
        navbarRef.current.style.position = 'sticky';
      }
    }
  };

  useLayoutEffect(() => {
    /* TODO: Adding animation settings */
    if (typeof window !== 'undefined') {
      window.isSticky = false;

      const addClasses = (navbar) => {
        navbar.classList.add('navigation--full');
        window.isSticky = true;
      };

      const removeClasses = (navbar) => {
        navbar.classList.remove('navigation--full');
        window.isSticky = false;
      };

      document.addEventListener('scroll', () => {
        if (window.matchMedia('(min-width: 85.375em)').matches) {
          if (window.scrollY >= 400 && !window.isSticky) {
            if (headerRef.current) headerRef.current.style.transform = 'none';
            const openTimeline = gsap.timeline();
            const { current } = navbarRef;
            if (current) {
              openTimeline
                .to(current, { y: '-150', duration: 0 })
                .to(current, { position: 'fixed', duration: 0 })
                .call(addClasses, [current])
                .to(current, { y: 0, duration: 0.3 }, 0);
            }
          } else if (window.scrollY > 0 && window.scrollY < 400 && window.isSticky === true) {
            const openTimeline = gsap.timeline();
            const { current } = navbarRef;
            if (current) {
              openTimeline
                .to(current, { y: '-150', duration: 0.3 }, 0)
                .to(current, { position: 'relative', duration: 0 })
                .call(removeClasses, [current])
                .to(current, { y: 0, duration: 0.3 }, 0.5);
            }
          }
        }
      });
    }
  }, []);

  useLayoutEffect(() => {
    handleResize();
    if (typeof window !== 'undefined') {
      /** Adding a listener on window resize */
      window.addEventListener('resize', handleResize);
    }
    return () => {
      /** Destroying the listener on component unmount */
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const handleOpenMenuMobile = () => {
    setIsMenuMobileActive(true);
    document.querySelector('body').classList.add('no-scroll');
  };

  const handleCloseMenuMobile = () => {
    setIsMenuMobileActive(false);
    document.querySelector('body').classList.remove('no-scroll');
  };

  const closeSecondaryNav = () => {
    setIsOpen(false);
    setIsMaskVisible(false);
    setActiveSecondaryNav(-1);
    document.querySelector('body').classList.remove('no-scroll');
  };

  const openSecondaryNav = (index) => {
    if (index >= 0 && index !== activeSecondaryNav) {
      setActiveSecondaryNav(index);
      setIsOpen(true);
      setIsMaskVisible(true);
      document.querySelector('body').classList.add('no-scroll');
    } else {
      closeSecondaryNav();
    }
  };

  useEffect(() => {
    document.querySelector('body').classList.remove('no-scroll');
  }, [path]);

  return (
    <header ref={headerRef} className="header">
      <Navigation
        navigation={navigation}
        topNavigation={topNavigation}
        handleOpenMobileMenu={() => handleOpenMenuMobile()}
        labelButton={labelButton}
        linkButton={linkButton}
        closeSecondaryNav={closeSecondaryNav}
        openSecondaryNav={openSecondaryNav}
        isOpen={isOpen}
        activeSecondaryNav={activeSecondaryNav}
        refNav={navbarRef}
      />
      <MobileNavigation
        isActive={isMenuMobileActive}
        bottomNavigation={topNavigation}
        navigation={navigation}
        handleCloseMenuMobile={() => handleCloseMenuMobile()}
      />
    </header>
  );
};

export default Header;
