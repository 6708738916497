import {
  SET_FILTERED_RECORDS,
  SET_ERROR,
  SET_ALL_NEWS,
  SET_NEWS_FORMATS,
  SET_NEWS_CATEGORIES,
  SET_NEWS_TOPICS,
  SET_NEWS_YEARS,
  FILTER_NEWS_BY_FORMAT,
  FILTER_NEWS_BY_CATEGORY,
  FILTER_NEWS_BY_TOPIC,
  FILTER_NEWS_BY_YEAR,
  SET_CURRENT_TOPICS
} from '../action-types';

const initialState = {
  filters: {
    format: {
      value: '',
      label: ''
    },
    category: {
      value: '',
      label: ''
    },
    topic: {
      value: '',
      label: ''
    },
    year: {
      value: '',
      label: ''
    },
  },
  options: {
    formats: [],
    categories: [],
    topics: [],
    currentTopics: [],
    years: []
  },
  filteredNews: [],
  allNews: [],
  error: ''
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERED_RECORDS:
      return { ...state, filteredNews: action.records };

    case SET_ALL_NEWS:
      return {
        ...state,
        allNews: [...action.allNews],
        filteredNews: [...action.allNews]
      };

    case SET_ERROR:
      return { ...state, error: action.error };

    case SET_NEWS_FORMATS:
      return {
        ...state,
        options: {
          ...state.options,
          formats: action.newsFormats
        }
      };

    case SET_NEWS_CATEGORIES:
      return {
        ...state,
        options: {
          ...state.options,
          categories: action.categories
        }
      };

    case SET_NEWS_TOPICS:
      return {
        ...state,
        options: {
          ...state.options,
          topics: action.topics
        }
      };

    case SET_NEWS_YEARS:
      return {
        ...state,
        options: {
          ...state.options,
          years: action.years
        }
      };

    case SET_CURRENT_TOPICS:
      return {
        ...state,
        options: {
          ...state.options,
          currentTopics: action.currentTopics
        }
      };

    case FILTER_NEWS_BY_FORMAT:
      return {
        ...state,
        filters: {
          ...state.filters,
          format: action.filter
        }
      };

    case FILTER_NEWS_BY_CATEGORY:
      return {
        ...state,
        filters: {
          ...state.filters,
          category: action.filter
        }
      };

    case FILTER_NEWS_BY_TOPIC:
      return {
        ...state,
        filters: {
          ...state.filters,
          topic: action.filter
        }
      };

    case FILTER_NEWS_BY_YEAR:
      return {
        ...state,
        filters: {
          ...state.filters,
          year: action.filter
        }
      };

    default:
      return state;
  }
};

export default filterReducer;
