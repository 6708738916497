import { useLayoutEffect } from 'react';

/**
 *
 * @param {function} setIsMedia
 * @param {string} media
 */
const useMatchMedia = (setIsMedia, media) => {
  /** Waiting the DOM mounting the component */
  useLayoutEffect(() => {
    const handleResize = () => {
      if (window !== 'undefined') {
        const isMatchedMedia = window.matchMedia(media).matches;
        /** Set TRUE if current window size match with the {media}  */
        if (isMatchedMedia) {
          setIsMedia(true);
        } else {
          setIsMedia(false);
        }
      }
    };
    handleResize();
    if (window !== 'undefined') {
      /** Adding a listener on window resize */
      window.addEventListener('resize', handleResize);
    }
    return () => {
      /** Destroying the listener on component unmount */
      if (window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
};

export const useCheckResize = (cb) => {
  useLayoutEffect(() => {
    const handleResize = () => {
      cb();
    };
    handleResize();
    if (window !== 'undefined') {
      /** Adding a listener on window resize */
      window.addEventListener('resize', handleResize);
    }
    return () => {
      /** Destroying the listener on component unmount */
      if (window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);
};

export default useMatchMedia;
