/* eslint-disable */
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import store from '../store/store';
import HeaderContainer from './HeaderContainer';
import FooterContainer from './FooterContainer';
import '../styles/main.scss';
import { useVirtPath } from '../hooks/hooks.ga-events';
import CommunicationBarContainer from './CommunicationBarContainer';
import StructuredData from '../components/StructuredData';
import logoDesktop from '../assets/images/logo-brand.svg';

gsap.registerPlugin(CSSRulePlugin);

const Layout = ({
  children,
  seoMetaTags,
  pathname,
  publicationDate,
  articleCategory,
  pageType,
  canonical,
  idEmergenzaLanding
}) => {
  const [isMaskVisible, setIsMaskVisible] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const refTransitionMask = useRef(null);
  const url = `https://unicef.it/${pathname}`;
  const isBrowser = typeof window !== 'undefined';

  /* eslint-disable */
  const inEntryAnimation = () => {
    /* eslint-disable */
    if (!window.isRouting) {
      const cover = refTransitionMask.current;
      const directionTo = '-100%';
      cover.classList.remove('deactive');
      const tl = gsap.timeline();
      tl.to(cover,
        {
          y: directionTo,
          ease: 'power1.easeIn',
          duration: 0.5,
          onStart: () => { setIsMainActive(true); },
          onComplete: () => { cover.classList.add('deactive'); }
        }, 1);
    } else {
      setIsMainActive(true);
    }
    window.isRouting = true;
  };

  const title = seoMetaTags ? seoMetaTags.tags.filter((tag) => tag.tagName === 'title')[0].content : '';
  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.isRouting) {
      useVirtPath(pathname, title);
    }
  }, []);

  /* useLayoutEffect(() => {
    const iubendaAcceptButton = document.querySelector('.iubenda-cs-accept-btn');
    const handleClickIubenda = () => {
      if (typeof window !== 'undefined' && window.isRouting) {
        useIubendaConsentGiven();
      }
    };
    if (iubendaAcceptButton) {
      iubendaAcceptButton.addEventListener('click', handleClickIubenda);
    }
    return function cleanupIubendaListener() {
      if (iubendaAcceptButton) {
        iubendaAcceptButton.removeEventListener('click', handleClickIubenda);
      }
    };
  }, []); */

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      inEntryAnimation();
    }
  }, []);

  useEffect(() => {
    const existingLinkCss = document.querySelector(`link[href="${process.env.GATSBY_CSS_DONATION_WIDGET}"]`);
    const existingJs = document.querySelector(`script[src="${process.env.GATSBY_SCRIPT_DONATION_WIDGET}"]`);
    const existingWdg = document.querySelector(`script[data-element="widget"]`);

    if (existingWdg) {
      existingWdg.remove();
    }

    if (existingJs) {
      existingJs.remove();
    }

    if (existingLinkCss) {
      existingLinkCss.remove();
    }

    if (idEmergenzaLanding && isBrowser) {
      const js = document.createElement('script');
      const linkCss = document.createElement('link');
      linkCss.id = "styleLink";
      linkCss.rel = "stylesheet";
      linkCss.href = `${process.env.GATSBY_CSS_DONATION_WIDGET}`;
      js.src = `${process.env.GATSBY_SCRIPT_DONATION_WIDGET}`;
      document.body.appendChild(linkCss);
      document.body.appendChild(js);
      js.onload = () => {
        const scriptWdg = document.createElement("script");
        scriptWdg.type = 'text/javascript';
        scriptWdg.setAttribute('data-element', 'widget');
        scriptWdg.textContent = `
              var offsetObj = { 320:{offset:0}, 768:{offset:50}, 1024:{offset:100}, 1200:{offset:200}, defaultOffset:200 };
              wdg.start({
                "uuid": "${idEmergenzaLanding}"
              });
              if (typeof (existingWindowDotAngular) !== 'undefined') {
                  window.angular = existingWindowDotAngular;
              }
            `;
        if (scriptWdg) {
          document.body.appendChild(scriptWdg);
        }
      };
    }

  }, [idEmergenzaLanding]);

  return (
    <Provider store={store}>
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
            function calculateBreakpoint() {
              if (window.matchMedia('(max-width: 767px)').matches) {
                return 'S';
              } else if (window.matchMedia('(max-width: 1023px)').matches) {
                return 'M';
              } else {
                return 'L';
              }
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'Page Meta',
              userLoginStatus: 'false',
              userId: undefined,
              userAgent: window.navigator.userAgent,
              newsletterSubscriber: 'false',
              fullPageReferrer: document.referrer ? document.referrer : undefined,
              pageCategory: undefined,
              pageSubcategory: undefined,
              originalPageUrl: window.location.origin,
              articleTitle: ${title ? `'${title}'` : undefined},
              articleCategory: ${articleCategory ? `'${articleCategory}'` : undefined},
              articlePublishDate: ${publicationDate ? `'${publicationDate}'` : undefined},
              pageType: '${pageType}',
              breakpoint: calculateBreakpoint(),
              language: 'it',
              internalTraffic: 'false',
              abTesting: undefined,
              abTestGroup: undefined
            });
          `
        }]}
      />
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_ID_GLOBAL}');
          `
        }]}
      />
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_ID_ITA}');
          `
        }]}
      />
      {/* <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
            var _iub = _iub || [];
            _iub.csConfiguration = {
              "cookiePolicyInOtherWindow":true,
              "consentOnContinuedBrowsing":false,
              "perPurposeConsent":true,"purposes":"1,4,5",
              "whitelabel":false,
              "lang":"it",
              "siteId":2028606,
              "cookiePolicyId":50247939,
              "cookiePolicyUrl":"https://www.unicef.it/cookie-policy/",
              "banner":{
                "acceptButtonDisplay":true,
                "customizeButtonDisplay":true,
                "acceptButtonCaptionColor":"white",
                "customizeButtonColor":"#212121",
                "customizeButtonCaptionColor":"white",
                "rejectButtonColor":"#0073CE",
                "rejectButtonCaptionColor":"white",
                "position":"float-top-right",
                "textColor":"white",
                "backgroundColor":"#2d2926",
                "fontSize":"12px",
                "fontSizeBody":"12px",
                "rejectButtonDisplay":true,
                "content": "<div id='iubenda-cs-title'>Informativa</div><div id='iubenda-cs-paragraph'>Il Comitato Italiano per l'UNICEF Fondazione onlus e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella <a href='https://www.unicef.it/cookie-policy' class='iubenda-cs-cookie-policy-lnk' target='_blank' rel='noopener'>cookie policy</a>.<br />Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa.</div>",
              },
              "callback": {
                "onConsentGiven": function() {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({ event: "iubenda_consent_given" })
                },
                "onPreferenceExpressed": function(preference) {
                  window.dataLayer = window.dataLayer || [];
                  if (preference.purposes[4]) {
                    window.dataLayer.push({'event': 'iubenda_consent_given_misurazione'});
                  }
                  if (preference.purposes[5]) {
                    window.dataLayer.push({'event': 'iubenda_consent_given_marketing'});
                  }
                }
              }
            };
          `
        }]}
      />
      <Helmet>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async />
      </Helmet> */}
      <Helmet>
        <meta property="og:url" content={url} />
        {
          seoMetaTags && seoMetaTags.tags.map((meta, index) => {
            if (meta.tagName === 'title') {
              return (
                <title key={index}>{meta.content.includes('UNICEF Italia') ? meta.content : `${meta.content} | UNICEF Italia`}</title>
              );
            }
            if (meta.tagName === 'meta' && meta.attributes.name) {
              return (
                <meta
                  key={index}
                  name={meta.attributes.name}
                  content={meta.attributes.content || ''}
                />
              );
            }
            if (meta.tagName === 'meta' && meta.attributes.property) {
              return (
                <meta
                  key={index}
                  content={meta.attributes.content}
                  property={meta.attributes.property}
                />
              );
            }
            return <></>;
          })
        }
        <link rel="canonical" href={canonical} />
      </Helmet>
      <StructuredData>
        {{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://www.unicef.com',
          logo: logoDesktop
        }}
      </StructuredData>
      <div ref={refTransitionMask} className="transition-mask deactive" />
      <div className={`main ${isMainActive ? 'main--active' : ''} ${pathname === '/' ? 'homepage' : ''}`}>
        <HeaderContainer setIsMaskVisible={setIsMaskVisible} />
        {isMaskVisible && <div className="background-mask" />}
        <main>
          {children}
        </main>
        <CommunicationBarContainer />
        <FooterContainer />
      </div>
    </Provider>
  );
};

export default Layout;

