import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Header from '../../components/Header';
import ReduxContainer from '../redux-container';

const HeaderNav = ({ setIsMaskVisible }) => {
  return (
    <StaticQuery
      query={graphql`
        query headerQuery {
          datoCmsHeader {
            id
            navigation {
              id
              label
              description
              menuList {
                ... on DatoCmsPagesSostienici {
                  originalId
                  id
                  title
                  slug
                  root
                  treeParent {
                    title
                    slug
                    root
                    treeParent {
                      title
                      slug
                      root
                    }
                  }
                }
                ... on DatoCmsPagesChisiamo {
                  originalId
                  id
                  title
                  slug
                  root
                  treeParent {
                    title
                    slug
                    root
                    treeParent {
                      title
                      slug
                      root
                    }
                  }
                }
                ... on DatoCmsPagesCosaFacciamo {
                  originalId
                  id
                  title
                  slug
                  root
                  treeParent {
                    title
                    slug
                    root
                    treeParent {
                      title
                      slug
                      root
                    }
                  }
                }
                ... on DatoCmsPagesVolontariato {
                  originalId
                  id
                  title
                  slug
                  root
                  treeParent {
                    title
                    slug
                    root
                    treeParent {
                      title
                      slug
                      root
                    }
                  }
                }
                ... on DatoCmsNewsArchive {
                  originalId
                  id
                  title
                  slug
                }
                ... on DatoCmsPublicationArchive {
                  originalId
                  id
                  title
                  slug
                }
                ... on DatoCmsSalaStampa {
                  originalId
                  id
                  title
                  slug
                }
                ... on DatoCmsStorie {
                  originalId
                  id
                  title
                  slug
                }
                ... on DatoCmsFaq {
                  id
                  originalId
                  title
                  slug
                }
              }
              urlFeatured
              titleFeatured
              coverImageFeatured {
                alt
                fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
            topNavigation {
              label
              url
              id
              icon
            }
            labelButton {
              value
            }
            linkButton
          }
        }
      `}
      render={(data) => {
        const {
          navigation,
          labelButton,
          linkButton,
          topNavigation
        } = {
          navigation: data.datoCmsHeader.navigation,
          labelButton: data.datoCmsHeader.labelButton,
          linkButton: data.datoCmsHeader.linkButton,
          topNavigation: data.datoCmsHeader.topNavigation
        };
        return (
          <ReduxContainer>
            <Header
              setIsMaskVisible={setIsMaskVisible}
              navigation={navigation}
              topNavigation={topNavigation}
              labelButton={labelButton}
              linkButton={linkButton}
            />
          </ReduxContainer>
        );
      }}
    />
  );
};

export default HeaderNav;
