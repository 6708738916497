import React from 'react';
import Link from '../TransitionLink';
import logoBrand from '../../assets/images/logo-brand-white.svg';

const FooterLogo = () => {
  return (
    <div className="footer__top-logo">
      <Link to="/">
        <img src={logoBrand} alt="UNICEF" />
      </Link>
    </div>
  );
};

export default FooterLogo;
