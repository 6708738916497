import React, { useState, useRef, useEffect } from 'react';
import Container from '../Container';
import PrimaryNav from '../PrimaryNav';
import SecondaryNav from '../SecondaryNav';
import SearchModal from '../SearchModal';
import Icon from '../Icons';
import Linked from '../Linked';
import { useOnClickOutside } from '../../hooks/hooks.outside-click-handler';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const Navigation = ({
  handleOpenMobileMenu,
  navigation,
  topNavigation,
  labelButton,
  linkButton,
  isOpen,
  activeSecondaryNav,
  closeSecondaryNav,
  openSecondaryNav,
  refNav
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, closeSecondaryNav);
  useEffect(() => {
    if (isSearchOpen) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }, [isSearchOpen]);

  return (
    <Container refProps={refNav} className="navigation">
      {
        isSearchOpen && (
          <SearchModal setIsSearchOpen={setIsSearchOpen} isSearchOpen={isSearchOpen} />
        )
      }
      <div ref={wrapperRef} className="navigation__wrapper">
        <TopNav
          topNavigation={topNavigation}
          setIsSearchOpen={setIsSearchOpen}
        />
        <PrimaryNav
          navigation={navigation}
          handleOpenMobileMenu={handleOpenMobileMenu}
          openSecondaryNav={openSecondaryNav}
          isOpen={isOpen}
          activeSecondaryNav={activeSecondaryNav}
          labelButton={labelButton}
          linkButton={linkButton}
        />
        <SecondaryNav
          isOpen={isOpen}
          navigation={navigation}
          openSecondaryNav={openSecondaryNav}
          closeSecondaryNav={closeSecondaryNav}
          activeSecondaryNav={activeSecondaryNav}
        />
      </div>
    </Container>
  );
};

const TopNav = ({ topNavigation, setIsSearchOpen }) => {
  const handleGaEvent = (level) => {
    useGaEvent('Navigation', 'TOP_L1', level);
  };

  return (
    <nav className="top-nav">
      <div className="top-nav__wrapper">
        {
          topNavigation && (
            <ul className="top-nav__menu-list">
              {
                topNavigation && topNavigation.map((item) => {
                  const {
                    id, url, label, icon
                  } = item;
                  return (
                    <li className="top-nav__menu-item" key={id}>
                      <Linked
                        url={url}
                        className="top-nav__menu-link caption"
                        onClick={() => handleGaEvent(label)}
                      >
                        <Icon className="top-nav__icon icon--18" path={icon} />
                        {label}
                      </Linked>
                    </li>
                  );
                })
              }
            </ul>
          )
        }
        <button
          type="button"
          className="top-nav__search"
          aria-label="Search"
          onClick={() => setIsSearchOpen(true)}
        >
          <Icon className="icon--24 top-nav__search" path="search" />
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
