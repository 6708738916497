import { SET_SEARCH_VALUE, SET_SEARCH_IS_OPEN } from '../action-types';

export const setSearchValue = (value) => {
  return { type: SET_SEARCH_VALUE, value };
};

export const submitSearch = (value) => {
  const googleInput = document.getElementById('gsc-i-id1');
  if (googleInput) {
    googleInput.value = value;
    const buttonSearch = document.getElementsByClassName('gsc-search-button gsc-search-button-v2')[0];
    buttonSearch.click();
  }
};

export const setSearchIsOpen = (value) => {
  return { type: SET_SEARCH_IS_OPEN, value };
};
