import React from 'react';
import Link from '../TransitionLink';
import { createRouteLink, isInternal } from '../../hooks/archive-hooks';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const AnchorLink = ({
  className, label, color, disabled, kind, url, target, title, onClick
}) => {
  const classes = `btn-${kind} btn-${kind}--${color} ${disabled ? 'btn--disabled' : ''}${className || ''} `;
  const handleGaEvent = () => {
    useGaEvent('Outbound Link', url);
    onClick();
  };
  return (
    <a onClick={() => handleGaEvent()} href={url} target={target ? '_blank' : false} title={title} className={classes}>
      <span className="btn__label">
        {label}
      </span>
    </a>
  );
};

AnchorLink.defaultProps = {
  onClick: () => {}
};

export const InternalLink = ({
  className, label, color, disabled, kind, url, title, onClick
}) => {
  const classes = `btn-${kind} btn-${kind}--${color} ${disabled ? 'btn--disabled' : ''}${className || ''}`;
  const handleGaEvent = () => {
    if (url === '/candidature') {
      useGaEvent('Customer Relations', 'Become Volunteer');
    }
    onClick();
  };

  return (
    <Link to={`${url}/`} title={title} className={classes} onClick={handleGaEvent}>
      <span className="btn__label">
        {label}
      </span>
    </Link>
  );
};

InternalLink.defaultProps = {
  onClick: () => {}
};

export const ButtonLink = ({
  className, label, color, kind, url, title, onClick
}) => {
  if (!isInternal(url)) {
    return (
      <AnchorLink
        kind={kind}
        label={label}
        color={color}
        url={url}
        title={title}
        target
        className={className}
        onClick={onClick}
      />
    );
  }
  return (
    <InternalLink
      kind={kind}
      label={label}
      color={color}
      title={title}
      className={className}
      url={`${createRouteLink(url) !== '' ? createRouteLink(url) : '/'}`}
    >
      {label}
    </InternalLink>
  );
};

export default AnchorLink;
