import { combineReducers } from 'redux';

import newsFilter from './reducer.news-filter';
import publicationsFilter from './reducer.publications-filter';
import search from './reducer.search';

export default combineReducers({
  newsFilter,
  publicationsFilter,
  search
});
