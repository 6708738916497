export const SET_ALL_NEWS = 'SET_ALL_NEWS';
export const SET_NEWS_FORMATS = 'SET_NEWS_FORMATS';
export const SET_NEWS_CATEGORIES = 'SET_NEWS_CATEGORIES';
export const SET_NEWS_TOPICS = 'SET_NEWS_TOPICS';
export const SET_NEWS_YEARS = 'SET_NEWS_YEARS';
export const SET_CURRENT_TOPICS = 'SET_CURRENT_TOPICS';

export const FILTER_NEWS_BY_FORMAT = 'FILTER_NEWS_BY_FORMAT';
export const FILTER_NEWS_BY_CATEGORY = 'FILTER_NEWS_BY_CATEGORY';
export const FILTER_NEWS_BY_TOPIC = 'FILTER_NEWS_BY_TOPIC';
export const FILTER_NEWS_BY_YEAR = 'FILTER_NEWS_BY_YEAR';

export const SET_FILTERED_RECORDS = 'SET_FILTERED_RECORDS';
export const SET_ERROR = 'SET_ERROR';

export const SET_ALL_PUBLICATIONS = 'SET_ALL_PUBLICATIONS';
export const SET_ALL_PUBLICATIONS_TOPICS = 'SET_ALL_PUBLICATIONS_TOPICS';
export const SET_PUBLICATIONS_SELECTED_TOPIC = 'SET_PUBLICATIONS_SELECTED_TOPIC';
export const SET_FILTERED_PUBLICATIONS = 'SET_FILTERED_PUBLICATIONS';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_IS_OPEN = 'SET_SEARCH_IS_OPEN';
