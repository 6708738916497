import React from 'react';

const Logo = ({ className }) => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 80 80"
      width="80"
      height="80"
      className={`logo ${className}`}
    >
      {/* <rect className="st0" /> */}
      <g>
        <path
          className="st1"
          d="M39.95,59.87c15.03,0,27.27-12.23,27.27-27.27c0-15.04-12.23-27.27-27.27-27.27
		c-15.04,0-27.26,12.23-27.26,27.27C12.68,47.64,24.91,59.87,39.95,59.87z M61.21,17.39c-1.39,0.94-2.87,1.79-4.39,2.54
		c-0.9-1.8-1.99-3.52-3.26-5.09c1.12-0.68,2.19-1.44,3.18-2.27C58.43,13.97,59.94,15.6,61.21,17.39z M55.85,11.85
		c-0.94,0.78-1.95,1.49-3.01,2.12c-1.34-1.53-2.84-2.93-4.48-4.15c0.49-0.46,0.96-0.95,1.38-1.48C51.92,9.22,53.97,10.4,55.85,11.85
		z M51.86,14.53c-1.46,0.79-3.01,1.43-4.59,1.92c-0.66-1.52-1.43-3.01-2.27-4.44c0.88-0.39,1.71-0.87,2.49-1.46
		C49.08,11.71,50.54,13.05,51.86,14.53z M48.63,7.93c-0.37,0.44-0.77,0.85-1.21,1.23c-1.44-0.99-2.97-1.83-4.57-2.53
		C44.84,6.84,46.78,7.27,48.63,7.93z M46.5,9.88c-0.66,0.47-1.36,0.86-2.09,1.17c-0.79-1.25-1.68-2.47-2.63-3.66
		C43.43,8.06,45.02,8.9,46.5,9.88z M40.51,7.58c1,1.21,1.95,2.5,2.81,3.86c-0.91,0.28-1.86,0.45-2.81,0.49V7.58z M40.51,13.05
		c1.17-0.06,2.32-0.26,3.43-0.63c0.84,1.39,1.58,2.84,2.24,4.34c-1.86,0.47-3.76,0.74-5.67,0.78V13.05z M39.37,7.63v4.3
		c-0.94-0.05-1.87-0.22-2.78-0.49C37.44,10.12,38.37,8.85,39.37,7.63z M35.52,11.05c-0.75-0.32-1.45-0.71-2.09-1.17
		c1.47-0.97,3.05-1.8,4.7-2.48C37.2,8.56,36.32,9.78,35.52,11.05z M37.1,6.62c-1.61,0.7-3.15,1.55-4.6,2.54
		c-0.44-0.39-0.84-0.8-1.21-1.23C33.16,7.26,35.1,6.83,37.1,6.62z M30.18,8.33c0.42,0.52,0.88,1.01,1.38,1.47
		c-1.09,0.8-3.47,2.92-3.47,2.92s0.33,0.05,0.63,0.12c0.21,0.05,0.65,0.18,0.65,0.18s2.09-1.78,3.07-2.49
		c0.76,0.58,1.6,1.07,2.49,1.45c-0.48,0.81-1.66,3.05-1.66,3.05s0.27,0.17,0.52,0.33c0.25,0.17,0.4,0.31,0.4,0.31
		s1.3-2.45,1.79-3.27c1.09,0.36,2.23,0.57,3.39,0.63v4.49c-0.64-0.02-2.29-0.08-2.96-0.16L36,17.34l0.23,0.35
		c0.18,0.26,0.32,0.52,0.43,0.75l0.05,0.1l0.11,0.01c0.48,0.04,1.99,0.09,2.55,0.11v8.17h1.13v-1.87c1.66-0.08,3.04-0.2,4.72-0.34
		l0.07-0.01l1.47-1.3l-0.8,0.07c-1.77,0.23-3.32,0.34-4.72,0.41c0,0-0.48,0.03-0.73,0.05v-5.19c2.05-0.04,4.11-0.33,6.12-0.86
		c0.55,1.35,1.58,4.62,1.58,4.62l1.06-0.33c0,0-1.03-3.25-1.57-4.6c1.7-0.53,3.34-1.23,4.9-2.08c1.25,1.54,2.33,3.22,3.23,5.01
		c-0.8,0.37-2.27,0.89-2.27,0.89c1.17,0.13,2.07,0.41,2.07,0.41s0.42-0.18,0.68-0.29c0.1,0.23,0.31,0.71,0.31,0.71l1.47,0.65
		l-0.17-0.41c0,0-0.49-1.16-0.6-1.43c1.56-0.77,3.08-1.65,4.54-2.63c2.78,4.25,4.26,9.19,4.26,14.29c0,8.48-4.06,16.03-10.33,20.81
		h0c-3.63-1.99-3.7-4.75-3.51-5.77c0.19-1.03,0.9-0.58,1.35-0.58c2.38,0,4.89-0.71,7.98-3.86c3.48-3.55,4.57-11.45-0.78-16.6
		c-5.77-5.55-11.9-4.58-15.86,0.41c-0.81,1.03-2.79,1.77-4.5,1.72c-2.54-0.1-0.94,1.9-0.94,2.32c0,0.42-0.39,0.71-0.61,0.65
		c-0.86-0.25-0.52,1.03-0.52,1.41c0,0.39-0.35,0.58-0.54,0.58c-1.06,0-0.78,0.96-0.74,1.22c0.03,0.25-0.07,0.7-0.42,0.9
		c-0.34,0.19-0.74,1.03-0.74,1.68c0,1.16,1.06,1.96,2.84,3.28c1.77,1.32,1.99,2.58,2.02,3.48c0.04,0.9,0.12,2.35,0.52,3.31
		c0.48,1.16,0.41,3.28-2.03,3.38c-2.99,0.12-8.3,2.51-8.75,2.67c-1.69,0.6-3.92,0.81-5.66,0.38c-0.94-0.66-1.83-1.39-2.68-2.17
		c-0.35-1.2,0.26-2.5,0.87-3.36c1,1,2.48,0.93,3.31,0.96c0.84,0.04,4.92-0.61,5.5-0.8c0.58-0.19,0.84-0.13,1.19,0
		c1.56,0.57,3.7,0.74,4.57-2.25c0.87-2.99-1.19-2.16-1.45-2.03c-0.26,0.13-0.62,0.1-0.49-0.16c0.38-0.76-0.19-0.68-0.51-0.71
		c-0.84-0.08-1.93,0.81-2.29,1.13c-0.35,0.32-0.71,0.29-0.84,0.23c-1.11-0.55-4.41,0.25-5.44-1.55c0.1-0.48-0.84-7.75-1.32-9.01
		c-0.11-0.27-0.19-0.71,0.12-1.19c0.84-1.26,3.15,0.09,4.41,0.06c1.5-0.04,1.74-0.67,2.16-1.18c0.39-0.49,0.68-0.22,0.9-0.35
		c0.28-0.18,0.02-0.53,0.11-0.72c0.1-0.2,0.21-0.1,0.46-0.32c0.26-0.23-0.04-0.71,0.1-0.9c0.37-0.56,1.39-0.11,0.78-1.57
		c-0.33-0.8,0.13-1.68,0.58-2.26c0.73-0.94,3.55-4.9-1.42-9.01c-4.73-3.92-9.53-3.61-12.62-0.26c-3.09,3.34-1.55,8.24-1.22,9.53
		c0.32,1.29-0.6,2.45-1.67,2.96c-0.85,0.4-2.78,1.69-4.23,2.96C14.03,21.5,20.4,12.27,30.18,8.33z"
        />
        <path
          className="st1"
          d="M3.99,33.93l0.27,0.35l0.35-1.74C5,29.47,4.49,23.03,3.36,20.15l-0.39-0.98l-0.18,0.49l-0.18,0.5
		c-0.25,0.68-0.72,2.12-0.96,4.03c-0.34,2.71-0.26,6.54,2.09,9.4C3.73,33.59,3.99,33.93,3.99,33.93z"
        />
        <path
          className="st1"
          d="M5.5,44.41c0,0,0.66,0.42,0.66,0.42l-0.03-0.77c-0.1-2.58-3.11-10.28-5.37-13.05l-0.64-0.78l-0.03,0.51
		l-0.04,0.51C-0.15,34.01,0,40.8,5.5,44.41z"
        />
        <path
          className="st1"
          d="M12.14,55.7l-0.28-0.77c-1.07-2.92-6.58-9.73-10.13-11.98l-0.8-0.51l0.1,0.46l0.09,0.47
		c0.81,3.91,4.51,10.24,10.23,12.07L12.14,55.7z"
        />
        <path
          className="st1"
          d="M5.97,28.38l0.04,0.89l0.62-0.52c2.3-1.91,5.18-6.78,6.04-9.55l0.31-1.01l-0.45,0.27l-0.45,0.27
		C7.81,21.34,5.82,24.49,5.97,28.38z"
        />
        <path
          className="st1"
          d="M10.34,26.68l-0.32,0.3l-0.32,0.3c-1.09,1.04-4.62,4.79-4.31,9.44C5.43,37.44,5.6,38.2,5.87,39l0.25,0.74
		l0.46-0.64c1.69-2.37,3.92-8.64,3.8-11.55L10.34,26.68z"
        />
        <path
          className="st1"
          d="M9.46,49.07l0.31-0.59c0.77-1.44,1.38-6.13,1.29-9.84c-0.04-1.31-0.17-2.39-0.38-3.13l-0.23-0.82l-0.24,0.35
		L9.96,35.4c-1.78,2.64-2.69,5.36-2.63,7.86c0.05,1.85,0.64,3.62,1.75,5.26L9.46,49.07z"
        />
        <path
          className="st1"
          d="M12.27,43.66l-0.11,0.37c-0.54,1.71-0.79,3.39-0.74,4.99c0.1,3.6,1.7,6.42,4.75,8.38l0.51,0.32l0.11-0.69
		c0-0.01,0.05-0.55,0.05-0.55c-0.09-2.93-2.27-10.31-3.98-12.56l-0.47-0.62L12.27,43.66z"
        />
        <path
          className="st1"
          d="M19.95,63l0.61-0.11l-0.35-0.51c-1.36-2.02-10.2-7.01-13.32-7.52l-0.85-0.14l0.22,0.37l0.22,0.37
		C8.78,59.31,12.67,64.27,19.95,63z"
        />
        <path
          className="st1"
          d="M17.92,52.52l-0.71-0.36l0.06,0.39l0.07,0.39c0.69,4.36,2.47,10.08,9.83,9.45l0.47-0.04l-0.11-0.46
		C27.03,59.91,20.28,54.15,17.92,52.52z"
        />
        <path
          className="st1"
          d="M75.74,34.28l0.27-0.35c0,0,0.26-0.34,0.26-0.34c2.35-2.86,2.43-6.69,2.08-9.4c-0.25-1.91-0.71-3.35-0.96-4.04
		l-0.19-0.5l-0.18-0.49l-0.39,0.97c-1.13,2.87-1.64,9.31-1.25,12.39L75.74,34.28z"
        />
        <path
          className="st1"
          d="M73.85,44.83c0,0,0.65-0.42,0.65-0.42C80,40.8,80.15,34,79.95,31.25l-0.04-0.51l-0.03-0.51l-0.64,0.78
		c-2.26,2.76-5.27,10.46-5.36,13.04L73.85,44.83z"
        />
        <path
          className="st1"
          d="M68.14,54.93l-0.29,0.77l0.79-0.25c5.72-1.83,9.43-8.16,10.23-12.07l0.1-0.47l0.09-0.46l-0.8,0.51
		C74.72,45.19,69.21,52,68.14,54.93z"
        />
        <path
          className="st1"
          d="M73.38,28.74L74,29.27l0.05-0.89c0.23-3.88-1.7-7.07-5.91-9.76l-0.45-0.28l-0.44-0.28l0.29,1.02
		C68.33,21.86,71.12,26.79,73.38,28.74z"
        />
        <path
          className="st1"
          d="M73.88,39.75L74.14,39c0.27-0.8,0.43-1.56,0.48-2.28c0.31-4.65-3.22-8.4-4.31-9.44l-0.32-0.31l-0.32-0.3
		l-0.03,0.88c-0.12,2.91,2.11,9.17,3.8,11.55L73.88,39.75z"
        />
        <path
          className="st1"
          d="M69.57,34.69l-0.24,0.82c-0.22,0.74-0.35,1.82-0.38,3.13c-0.09,3.71,0.52,8.4,1.28,9.84l0.32,0.59l0.38-0.56
		c1.11-1.64,1.7-3.41,1.74-5.26c0.06-2.5-0.84-5.21-2.62-7.86l-0.24-0.36L69.57,34.69z"
        />
        <path
          className="st1"
          d="M67.61,43.3l-0.47,0.62c-1.71,2.25-3.89,9.63-3.97,12.56c0,0,0.04,0.54,0.05,0.55l0.11,0.7l0.52-0.32
		c3.05-1.95,4.65-4.77,4.75-8.38c0.05-1.59-0.2-3.27-0.75-4.99l-0.12-0.38L67.61,43.3z"
        />
        <path
          className="st1"
          d="M59.78,62.38l-0.34,0.51L60.05,63c7.29,1.27,11.17-3.69,13.47-7.53l0.22-0.37l0.22-0.37l-0.85,0.14
		C69.99,55.37,61.15,60.36,59.78,62.38z"
        />
        <path
          className="st1"
          d="M40,65.5c-4.9-2.05-12.8-3.39-22.97-0.63l-1.14,0.31l1.1,0.45c4.63,1.89,10.44,2.46,13.8,1.32
		c1.27-0.43,2.03-1.12,2.97-2.04c1.58,0.16,3.14,0.65,4.63,1.33c-1.34,0.74-5.58,3.26-8.45,7.02l-0.2,0.27l0.23,0.25
		c0.23,0.25,0.79,0.69,1.16,0.81l0.25,0.08c0,0,0.17-0.2,0.17-0.2c2.05-2.55,5.04-5.46,8.46-7.41c3.42,1.95,6.41,4.86,8.46,7.41
		c0,0,0.18,0.2,0.18,0.2l0.25-0.08c0.37-0.12,0.93-0.56,1.16-0.81l0.23-0.25l-0.2-0.27c-2.88-3.76-7.12-6.29-8.46-7.02
		c1.49-0.68,3.04-1.17,4.63-1.33c0.94,0.92,1.71,1.61,2.98,2.04c3.36,1.13,9.17,0.57,13.8-1.32l1.1-0.45l-1.15-0.31
		C52.8,62.1,44.9,63.45,40,65.5z"
        />
        <path
          className="st1"
          d="M52.47,61.89l-0.12,0.46l0.47,0.04c7.37,0.63,9.14-5.09,9.84-9.45l0.07-0.39l0.06-0.39l-0.71,0.37
		C59.72,54.15,52.97,59.91,52.47,61.89z"
        />
        <path
          className="st1"
          d="M5.16,22.5c0,0.01,0.18,0.82,0.18,0.82l0.51-0.47c2.24-2.05,3.92-5.52,4.6-9.52l0.2-1.12l-0.43,0.38
		l-0.43,0.37c-1.6,1.42-5.25,5.15-4.69,9.26C5.1,22.23,5.16,22.5,5.16,22.5z"
        />
        <path
          className="st1"
          d="M74.16,22.85l0.51,0.47c0,0,0.18-0.81,0.18-0.81c0,0,0.05-0.27,0.06-0.29c0.57-4.1-3.09-7.84-4.69-9.25
		l-0.43-0.37l-0.43-0.38l0.2,1.13C70.24,17.33,71.92,20.8,74.16,22.85z"
        />
      </g>
    </svg>

  );
};

export default Logo;
