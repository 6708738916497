import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import Container from '../Container';
import placeholderLogo from '../../images/unicef-logo-in-nav.png';
import Icon from '../Icons';
import { setSearchValue, setSearchIsOpen, submitSearch } from '../../actions/action.search';
import Logo from '../Logo';
import Link from '../TransitionLink';

const NavigationsSearch = ({
  setIsSearchOpen,
  dispatchSetSearchValue,
  dispatchSetSearchIsOpen
}) => {
  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.length >= 2) {
      dispatchSetSearchValue(value);
      dispatchSetSearchIsOpen(false);
      submitSearch(value);
      if (window.location.pathname === '/search') {
        setIsSearchOpen(false);
      } else {
        const anchor = document.getElementsByClassName('search__link-button');
        anchor[0].click();
      }
    }
  };

  const [status, setStatus] = useState('idle');

  useLayoutEffect(() => {
    setTimeout(() => {
      setStatus('active');
    }, 100);
  }, []);

  const handleClick = () => {
    setStatus('inactive');
    setTimeout(() => {
      setIsSearchOpen(false);
    }, 1000);
  };

  return (
    <Container className={`navigations-search navigation-search--${status}`}>
      <div className="navigations-search__wrapper">
        <div className="navigations-search__header">
          <img src={placeholderLogo} className="navigations-search__logo" alt="logo di Unicef" />
          <button
            type="button"
            onClick={() => handleClick()}
            className="navigations-search__btn-close"
          >
            <span className="navigations-search__label label-small">chiudi</span>
            <Icon className="icon--36 navigations-search__icon-close" path="close" />
          </button>
        </div>
        <div className="search">
          <form onSubmit={(e) => handleSubmit(e)} className="search__form">
            <label htmlFor="site-search" className="search__search-label">
              <button className="search__icon-search" type="submit">
                <Icon className="icon--36" path="search" />
              </button>
              <input
                onChange={(e) => setValue(e.target.value)}
                className="search__input paragraph"
                placeholder="Cosa stai cercando?"
                id="site-search"
                type="search"
                value={value}
              />
            </label>
          </form>
        </div>
      </div>
      <Link className="search__link-button" to="/search" />
      <Logo className="logo--black navigations__logo" />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetSearchValue: (value) => dispatch(setSearchValue(value)),
    dispatchSetSearchIsOpen: (value) => dispatch(setSearchIsOpen(value))
  };
};

export default connect(null, mapDispatchToProps)(NavigationsSearch);
