import React from 'react';
import { connect } from 'react-redux';
import Icon from '../Icons';
import { setSearchValue } from '../../actions/action.search';

/**
 * @param {callback} submitCallback: Function called on submit to close the
 */
const Search = ({ submitCallback, value, dispatchSetSearchValue }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    submitCallback(value);
  };

  return (
    <div className="search">
      <form onSubmit={(e) => handleSubmit(e)} className="search__form">
        <label htmlFor="site-search" className="search__search-label">
          <button className="search__icon-search" type="submit">
            <Icon className="icon--36" path="search" />
          </button>
          <input
            onChange={(e) => dispatchSetSearchValue(e.target.value)}
            className="search__input paragraph"
            placeholder="Cosa stai cercando?"
            id="site-search"
            type="search"
            value={value}
          />
        </label>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    value: state.search.value
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetSearchValue: (value) => dispatch(setSearchValue(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
