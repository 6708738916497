/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Container from '../Container';
import Icon from '../Icons';
import { ButtonLink } from '../Link';

const CommunicationBar = ({
  title, labelButton, banner, expirationTime, delay
}) => {
  const [isVisible, setIsVisible] = useState(0);
  const [isVisibleStart, setIsVisibleStart] = useState(false);
  const dictionary = {
    giallo: '1',
    azzurro: '2',
  };

  const showCommunicationBar = (typeof localStorage !== 'undefined') ? (localStorage.getItem('showCommunicationBar') || 1) : 1;

  const getTimeStamp = () => {
    if (typeof localStorage !== 'undefined') {
      return +localStorage.getItem('timeStamp');
    } else {
      return 0;
    }
  }

  const getCounter = () => {
    if (typeof localStorage !== 'undefined') {
      return +localStorage.getItem('closingCounter');
    } else {
      return 0;
    }
  }

  const setLocalStorage = (item, value) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(item, value);
    }
  }
  
  const convertDaysInMilliseconds = (days) => {
    return days * 24 * 60 * 60 * 1000;
  }


  useEffect(() => {
    if (!getTimeStamp() && getCounter() < 2) {
      setLocalStorage('showCommunicationBar', 1);
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    setIsVisible(+showCommunicationBar);
    const timeStamp = getTimeStamp();
    const difference = Date.now() - timeStamp;
    const expirationTimeMilliseconds = convertDaysInMilliseconds(expirationTime);
    const counter = getCounter();

    if (difference > expirationTimeMilliseconds) {
      // Expired
      setLocalStorage('showCommunicationBar', 1);
      localStorage.setItem('closingCounter', 0);
      setIsVisible(true);
    }
    if (counter < 2) {
      // Closed less than 2 times
      setLocalStorage('showCommunicationBar', 1);
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    const delayInMilliseconds = delay * 1000
    if (isVisible) {
      setTimeout(() => {
        setIsVisibleStart(true);
      }, delayInMilliseconds);
    }
  }, [isVisible]);

  const theme = dictionary[`${banner && banner.model.apiKey === "donation_bar" ? 'giallo' : 'azzurro'}`];

  const handleVisibility = () => {
    setIsVisible(0);
    if (typeof localStorage !== 'undefined') {
      const counter = getCounter();
      localStorage.setItem('showCommunicationBar', 0);
      localStorage.setItem('closingCounter', counter + 1);
      setLocalStorage('timeStamp', Date.now());
    }
  };

  const buttonColor = `${theme === '1' ? 'white' : 'border-white'}`;
  return (
    <Container className={`communication-bar communication-bar--theme-v${theme} ${(isVisible && isVisibleStart) ? 'communication-bar--open' : ''}`}>
      <div className="communication-bar__wrapper">
        <div className="communication-bar__wrapper-text">
          <button type="button" className="communication-bar__btn-close" onClick={() => handleVisibility()}>
            <Icon className="icon--24" path="close" />
          </button>
          <p className="heading-xs communication-bar__text">{title}</p>
        </div>
        {
          banner.model.apiKey === "general_bar" ? (
            <ButtonLink
              label={labelButton}
              color={buttonColor}
              kind="small"
              url={banner.url}
              className="communication-bar__btn-call"
            />
          ) : (
            <ButtonLink
            label={labelButton}
            color={buttonColor}
            kind="small"
            url={`tel:${banner.phone}`}
            className="communication-bar__btn-call"
            />
          )
        }
      </div>
    </Container>
  );
};

export default CommunicationBar;
