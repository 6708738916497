/* eslint-disable max-len */
const isBrowser = typeof window !== 'undefined'; // If window is defined we are on browser

export const useGaEvent = (eventCategory, eventAction, eventLabel, eventValue, additionalParameters) => {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ...additionalParameters
    });
  }
};

export const useVirtPath = (virtPagePath, virtPageTitle) => {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtPath',
      virtPagePath,
      virtPageTitle,
      virtPath: true
    });
  }
};

// export const useIubendaConsentGiven = () => {
//   if (isBrowser) {
//     window.dataLayer = window.dataLayer || [];
//     window.dataLayer.push({
//       event: 'iubenda_consent_given',
//     });
//   }
// };
