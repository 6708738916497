import React, { useState } from 'react';
import Link from '../TransitionLink';
import Icon from '../Icons';
import Search from '../Search';
import useRemoveBackspaces from '../../hooks/hooks.strings';
import { useCreateSlug, useTraverseTree } from '../../hooks/archive-hooks';
import Linked from '../Linked';

const MobileNavigation = ({
  navigation, isActive, bottomNavigation, handleCloseMenuMobile
}) => {
  const [activeSecondaryNav, setActiveSecondaryNav] = useState(0);
  const [isSecondary, setIsSecondary] = useState(false);
  const openSecondaryNav = (index) => {
    setActiveSecondaryNav(index);
    setIsSecondary(true);
  };

  const closeSecondaryNav = () => {
    setIsSecondary(false);
  };

  const handleOpenSearch = () => {
    setIsSecondary(true);
    setActiveSecondaryNav(-1);
  };

  const handleSearchSubmit = () => {
    setIsSecondary(false);
    setActiveSecondaryNav(0);
    handleCloseMenuMobile();
  };

  const navigationClasses = useRemoveBackspaces(
    `mobile-navigation
    ${!isActive ? 'mobile-navigation--disabled' : ''}
    ${isActive ? 'mobile-navigation--active' : ''}`
  );

  const navigationWrapperClasses = useRemoveBackspaces(
    `mobile-navigation__wrapper
    ${isSecondary ? 'mobile-navigation__wrapper--secondary' : ''}`
  );

  const searchClasses = useRemoveBackspaces(
    `mobile-navigation__secondary-wrapper
    ${activeSecondaryNav !== -1 ? 'mobile-navigation__secondary-wrapper--disabled' : ''}`
  );

  return (
    <div className={navigationClasses}>
      <div className={navigationWrapperClasses}>
        <div className="mobile-navigation__primary-wrapper">
          <div className="mobile-navigation__content">
            <MobileNavigationHeader
              handleCloseMenuMobile={handleCloseMenuMobile}
              handleOpenSearch={handleOpenSearch}
              type="primary"
            />
            <PrimaryNavList
              navigation={navigation}
              openSecondaryNav={openSecondaryNav}
            />
            <BottomNavigation
              bottomNavigation={bottomNavigation}
            />
          </div>
        </div>
        {
          navigation && navigation.map((menuItem, index) => {
            const secondaryNavClasses = useRemoveBackspaces(
              `mobile-navigation__secondary-wrapper
              ${activeSecondaryNav !== index
                ? 'mobile-navigation__secondary-wrapper--disabled' : ''}`
            );
            const {
              menuList
            } = menuItem;
            return (
              <div className={secondaryNavClasses} key={menuItem.id}>
                <div className="mobile-navigation__content">
                  <MobileNavigationHeader
                    closeSecondaryNav={closeSecondaryNav}
                    type="secondary"
                  />
                  <div className="mobile-navigation__title heading-xl">
                    {menuItem.label}
                  </div>
                  <SecondaryNavList
                    secondaryNavigation={menuList}
                  />
                </div>
              </div>
            );
          })
        }
        <div className={searchClasses}>
          <div className="mobile-navigation__content">
            <MobileNavigationHeader
              closeSecondaryNav={closeSecondaryNav}
              type="secondary"
            />
            <Search
              submitCallback={handleSearchSubmit}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

const BottomNavigation = ({ bottomNavigation }) => {
  return (
    bottomNavigation && (
      <div className="bottom-navigation">
        <ul className="bottom-navigation-list">
          {
            bottomNavigation && bottomNavigation.map((bottomNavigationItem) => {
              const {
                label, url, id, icon
              } = bottomNavigationItem;
              return (
                <li className="bottom-navigation-item" key={id}>
                  <Linked
                    url={url}
                    className="bottom-navigation-link paragraph"
                  >
                    <Icon className="bottom-navigation__icon icon--24" path={icon} />
                    {label}
                  </Linked>
                </li>
              );
            })
          }
        </ul>
      </div>
    )
  );
};

const PrimaryNavList = ({ navigation, openSecondaryNav }) => {
  return (
    navigation && (
      <nav>
        <ul className="mobile-navigation__menu-list">
          {
            navigation.map((menuItem, index) => {
              return (
                <li className="mobile-navigation__menu-item" key={menuItem.id}>
                  <button type="button" onClick={() => openSecondaryNav(index)}>
                    <span className="label text-color-white">
                      {menuItem.label}
                    </span>
                    <Icon
                      path="arrow-right"
                      className="icon--24 mobile-navigation__menu-item-arrow"
                    />
                  </button>
                </li>
              );
            })
          }
        </ul>
      </nav>
    )
  );
};

const SecondaryNavList = ({ secondaryNavigation }) => {
  const menuLabel = {
    7479245: 'In Italia',
    6136345: 'Nel Mondo'
  };
  return (
    <nav>
      <ul>
        {
          secondaryNavigation && secondaryNavigation.map((subMenuItem) => {
            const menuTemp = [];
            const slugDef = useTraverseTree(menuTemp, subMenuItem);
            const slug = useCreateSlug(slugDef);
            return (
              <li className="mobile-navigation__menu-item label" key={slug}>
                {menuLabel[subMenuItem.originalId] && (
                  <div className="secondary-nav__label-menu">{menuLabel[subMenuItem.originalId]}</div>
                )}
                <Link to={`/${slug}/`}>
                  {subMenuItem.title}
                </Link>
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

const MobileNavigationHeader = ({
  handleCloseMenuMobile,
  closeSecondaryNav,
  handleOpenSearch,
  type
}) => {
  if (type === 'primary') {
    return (
      <div className="mobile-navigation__header">
        <button type="button" onClick={() => handleCloseMenuMobile()}>
          <Icon className="icon--36" path="close" />
        </button>
        <button
          type="button"
          onClick={() => handleOpenSearch()}
        >
          <Icon className="icon--36" path="search" />
        </button>
      </div>
    );
  }
  if (type === 'secondary') {
    return (
      <div className="mobile-navigation__header">
        <button
          type="button"
          className="mobile-navigation__back-to-menu label text-color-white"
          onClick={() => closeSecondaryNav()}
        >
          <Icon path="arrow-left" className="icon--24 mobile-navigation__menu-item-arrow" />
          <span>MENU PRICIPALE</span>
        </button>
      </div>
    );
  }
};

export default MobileNavigation;
