import {
  SET_ALL_PUBLICATIONS,
  SET_ALL_PUBLICATIONS_TOPICS,
  SET_PUBLICATIONS_SELECTED_TOPIC,
  SET_FILTERED_PUBLICATIONS
} from '../action-types';

const initialState = {
  selectedTopic: '',
  filteredPublications: [],
  allPublications: [],
  error: '',
  allTopics: [
    {
      name: 'Tutti',
      slug: ''
    }
  ]
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PUBLICATIONS:
      return { ...state, allPublications: [...action.allPublications], filteredPublications: [...action.allPublications] };
    case SET_ALL_PUBLICATIONS_TOPICS:
      return { ...state, allTopics: [{ name: 'Tutti', slug: '' }, ...action.allPublicationTopics] };
    case SET_PUBLICATIONS_SELECTED_TOPIC:
      return { ...state, selectedTopic: action.selectedTopic };
    case SET_FILTERED_PUBLICATIONS:
      return { ...state, filteredPublications: action.filteredPublications };
    default:
      return state;
  }
};

export default filterReducer;
