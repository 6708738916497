import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import CommunicationBar from '../../components/CommunicationBar';

const CommunicationBarContainer = () => {
  return (
    <StaticQuery
      query={graphql`
      query communicationQuery {
        datoCmsCommunicationBar {
          id
          title
          isActive
          expirationTime
          delay
          model {
            apiKey
          }
          banner {
            ... on DatoCmsDonationBar {
              model {
                apiKey
              }
              phone
            }
            ... on DatoCmsGeneralBar {
              model {
                apiKey
              }
              url
            }
          }
        }
      }
    `}
      render={(data) => {
        const {
          title, banner, expirationTime, delay, isActive
        } = data.datoCmsCommunicationBar;

        if (isActive) {
          return (
            <CommunicationBar
              title={title}
              banner={banner[0]}
              expirationTime={expirationTime}
              delay={delay}
              labelButton={banner && banner[0].model.apiKey === 'donation_bar' ? 'Chiama' : 'Scopri di più'}
            />
          );
        }
      }}
    />
  );
};

export default CommunicationBarContainer;
