import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Footer from '../../components/Footer';

const FooterContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query footerQuery {
          datoCmsFooter {
            menu {
              originalId
              label
              id
              menu {
                label
                url
                originalId
                id
              }
            }
            labelSocial {
              id
              value
            }
            descriptionCopyright
            socialFooter {
              slug
              name
              url
              id
            }
          }
        }
      `}
      render={
        (data) => {
          const {
            footerMenus,
            socials,
            descriptionCopyright,
            labelSocial
          } = {
            footerMenus: data.datoCmsFooter.menu,
            socials: data.datoCmsFooter.socialFooter,
            descriptionCopyright: data.datoCmsFooter.descriptionCopyright,
            labelSocial: data.datoCmsFooter.labelSocial.value
          };
          return (
            <Footer
              footerMenus={footerMenus}
              socials={socials}
              descriptionCopyright={descriptionCopyright}
              labelSocial={labelSocial}
            />
          );
        }
      }
    />
  );
};

export default FooterContainer;
