/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Icon from '../Icons';
import Linked from '../Linked';
import FooterMenu from '../FooterMenu';
import FooterLogo from '../FooterLogo';
import Logo from '../Logo';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const Footer = ({
  descriptionCopyright, footerMenus, socials, labelSocial
}) => {
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleGaSocialEvent = (url, social) => {
    useGaEvent('Social Network Click-out', `Footer::${capitalize(social)}`, url);
  };
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <FooterLogo />
        <nav className="footer__social">
          {labelSocial && <span className="footer__label label-small">{labelSocial}</span>}
          <div className="footer__social--links">
            {
              socials && socials.map((social) => {
                const { url, slug, id } = social;
                return (
                  <a
                    key={id}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="footer__social-url"
                    onClick={() => handleGaSocialEvent(url, slug)}
                  >
                    <Icon className="icon--40" path={`social-ico-${slug}`} />
                  </a>
                );
              })
            }
          </div>
        </nav>
        <div className="footer__menus">
          {
            footerMenus && footerMenus.map((footerMenu) => {
              const { label, menu, id } = footerMenu;
              return (
                <FooterMenu
                  key={id}
                  labelMenu={label}
                  menuItems={menu}
                />
              );
            })
          }
        </div>
        <div className="footer__policy">
          <ul className="footer__menu-policy">
            <li className="menu-policy__item label-small">
              <Linked url="https://www.avanade.com/it-it" target="_blank" className="text-color-white-opacity-05">Credits</Linked>
            </li>
            <li className="menu-policy__item label-small">
              <Linked url="https://www.unicef.it/condizioni-di-utilizzo-sul-sito-e-sul-copyright" target className="text-color-white-opacity-05">Condizioni di utilizzo</Linked>
            </li>
            <li className="menu-policy__item label-small">
              <Linked url="https://www.unicef.it/note-sulla-privacy" target className="text-color-white-opacity-05">Privacy Policy</Linked>
            </li>
            <li className="menu-policy__item label-small">
              <Linked url="https://www.unicef.it/cookie-policy" target className="text-color-white-opacity-05">Cookie Policy</Linked>
            </li>
            <li className="menu-policy__item label-small">
              <a href="#unicef" className="text-color-white-opacity-05 iubenda-cs-preferences-link">Preferenze Cookie</a>
            </li>
          </ul>
          {descriptionCopyright && <div className="footer__paragraph caption" dangerouslySetInnerHTML={{ __html: `${descriptionCopyright}` }} />}
        </div>
      </div>
      <Logo className="logo--white footer__logo" />
    </footer>
  );
};

/*
const PolicyItem = ({
  label, link, title, target
}) => (
  <li className="menu-policy__item label-small">
    {
      target ? (
        <a
          className="text-color-white-opacity-05 iubenda-nostyle no-brand iubenda-embed"
          href={link}
          title={title}
          target="_blank"
        >
          {label}
        </a>
      ) : (
        <a
          className="text-color-white-opacity-05 iubenda-nostyle no-brand iubenda-embed"
          href={link}
          title={title}
        >
          {label}
        </a>
      )
    }
  </li>
);
 */

export default Footer;
