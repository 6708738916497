import { SET_SEARCH_VALUE, SET_SEARCH_IS_OPEN } from '../action-types';

const initialState = {
  value: '',
  isOpen: false
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        value: action.value
      };
    case SET_SEARCH_IS_OPEN:
      return {
        ...state,
        isOpen: action.value
      };
    default:
      return state;
  }
};

export default filterReducer;
