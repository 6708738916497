import React from 'react';
import Linked from '../Linked';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const FooterMenu = ({ labelMenu, menuItems }) => {
  const handleGaEvent = (eventLabel) => {
    useGaEvent(
      'Navigation',
      'Footer',
      `${eventLabel}`
    );
  };

  return (
    <nav className="footer__menu">
      <span className="footer__label label-small">{labelMenu}</span>
      <ul className="footer__menu--group">
        {
          menuItems && menuItems.map((itemFooterMenu) => {
            const { label, url, originalId } = itemFooterMenu;
            return (
              <li className="menu__item label-small" key={originalId}>
                <Linked onClick={() => handleGaEvent(label)} url={url} className="menu__link">{label}</Linked>
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

export default FooterMenu;
