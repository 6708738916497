import { useEffect, useLayoutEffect } from 'react';

export const useResetPage = (setCurrentPage, toObserve) => {
  /** Set page to 1 when the filter changes */
  useEffect(() => {
    setCurrentPage(1);
  }, [setCurrentPage, toObserve]);
};

export const useChangePage = (setResults, results, setCurrentPage, page) => {
  setResults(results);
  setCurrentPage(page);
};

export const useChangeDisplayPagesOnResize = (setDisplayPages) => {
  useLayoutEffect(() => {
    const resizeListener = () => {
      if (window !== 'undefined') {
        const { matches } = window.matchMedia('(max-width: 767px)');
        if (matches) {
          setDisplayPages(4);
        } else {
          setDisplayPages(10);
        }
      }
    };
    resizeListener();
    if (window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      if (window !== 'undefined') {
        window.removeEventListener('resize', resizeListener);
      }
    };
  }, [setDisplayPages]);
};

export const isInternal = (url) => {
  const reg = new RegExp('^https?:\\/\\/(www\\.)?unicef.it');
  return reg.test(url);
};

export const createRouteLink = (url) => {
  const reg = new RegExp('^https?:\\/\\/(www\\.)?unicef.it');
  const routeLink = url.match(reg);
  const link = url.split(routeLink[0]);
  return link[1] !== '' ? link[1] : '/';
};

export const useCreateSourceHeaderImage = (coverDesktop, coverMobile) => {
  if (coverDesktop && coverMobile) {
    return [
      coverMobile.fluid,
      {
        ...coverDesktop.fluid,
        media: '(min-width: 768px)',
      },
    ];
  }
  return coverDesktop.fluid;
};

export const useTraverseTree = (menuTmp, menuCurrent) => {
  menuTmp.push(menuCurrent.slug);
  if (menuCurrent.root || !menuCurrent.treeParent) {
    return menuTmp;
  }
  return useTraverseTree(menuTmp, menuCurrent.treeParent);
};

export const useCreateSlug = (slug) => {
  return (slug.reverse()).join('/');
};

export const useSizeColsIsRoot = (root) => {
  if (root) {
    return 'large';
  }
  return 'small';
};
